
















































// import { Component, Vue, Watch, Prop } from 'vue-property-decorator'


import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import {
  getExamDetail,
  getExamChance
} from '@/modules/course/api'
import { counterTimeBySec } from '@/modules/course/filters/utils'
// import * as ExamApi from '@/a-api/exam'
// import { ClassInfo, CourseInfo } from '@/modules/course/models'
@Component
export default class examPage extends Vue {
  @Prop() id!:string
  @Prop() type!:any
  @Prop() contentId!:any
  detail: any = {}
  timeText: any = ''
  examTimesObj: any = {
    remainingTimes: 0, // 剩余测验次数
    examTimesLimit: 0, // 考试次数限制，如果是0 则是无限制
    examTimes: 0 // 已经考试的次数
  }

  get isNotChance() {
    return this.examTimesObj.examTimesLimit !== 0 && this.examTimesObj.remainingTimes === 0
  }

  get examMode() {
    let mode = this.detail.exam_mode
    if (mode === 'generic') {
      return 'exam'
    } else if (mode === 'random') {
      return 'random'
    } else {
      return 'practice'
    }
  }

  mounted() {
    // console.log(this.id, 'idididid');
    this.$nextTick(() => {
      // console.log(this.id, 'idididid');
      this.initPage()
    })
    // this.initPage()
  }

  initPage() {
    this.getExamChance()
    getExamDetail(this.id).then(res => {
      this.detail = res
      console.log(this.detail, 'this.detail');
      const count = counterTimeBySec(res.time_limit * 60)
      let timeText = ''
      if (Number(count.day)) {
        timeText = `${count.day}日${count.hour}时${count.min}分`
      } else {
        timeText = `${count.hour}时${count.min}分`
      }
      this.timeText = timeText
      console.log(this.timeText, 'this.timeText');
      
    }).catch(e => {
    })
  }

  getExamChance() {
    getExamChance({id: this.id}).then(res => {
      console.log(res);
      
      this.examTimesObj = {
        remainingTimes: res.remaining_examine_times,
        examTimesLimit: res.examine_times_limit,
        examTimes: res.exam_times
      }
    })
  }

  //开始做题
  gotoExam(type: string) {
    console.log(this.type, 'this.type');
    console.log(this.contentId, 'this.contentId');
    
    if(type === 'exam'){
      this.$router.push({
        name: 'exam',
        params: {
          id: this.id
        },
        query: {
          renderType: 'exam',
          contentType: this.type,
          contentId: this.contentId,
          examType: this.examMode
        }
      })
    } else {
      this.$router.push({
        name: 'examResult',
        params: {
          id: this.id
        },
        query: {
          contentType: this.type,
          contentId: this.contentId
        }
      })
    }
  }
}
