









































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import ContentLayout from '@/modules/course/components/content-layout.vue'
import { CharpterClass, ClassInfo, CourseInfo } from '@/modules/course/models'
import examPage from './exam.vue'
import ClassList from '@/modules/course/pages/course-class/components/class-list/index.vue'
import {
  getChapterList,
  getCourseDetail,
  getExamDetail,
  getClassCourseDetail,
  getUnlockList,
  getViewCount
} from '@/modules/course/api'
import {checkLogin, getSign} from '@/utils/user';
import ExtLearning from '@/modules/course/pages/course-detail/components/ext-learning.vue'
import Students from '@/modules/course/pages/course-detail/components/students.vue'
import ClassCourseInfo from '@/modules/course/pages/course-class/components/class-course-info.vue'

@Component({
  components: {
    ContentLayout,
    examPage,
    ClassList,
    ExtLearning,
    Students,
    ClassCourseInfo
  }
})

export default class ClassIndex extends Vue {
  @Prop() id!: any
  @Prop() type!:any
  @Prop() contentId!:any
  isLoading: boolean = true
  errorObj: any = null
  eId: any = ''
  classDetail: ClassInfo = {}
  courseDetail: CourseInfo = {}
  charpterList: Array<CharpterClass> = []
  courseId: any = ''
  cId: any = ''
  unlockData: any = {}

  mounted() {
    // console.log(this.id, 'this.id');
    // if(this.id){
    //   this.eId = this.id
    // }
    console.log(this.$route.query.contentId, 'this.$route');
    console.log(this.id, 'thisthis');
    this.$nextTick(() => {
      this.pageInit()
    })
    console.log(this.id, 'this.id');
    
    
    
    // console.log(this.$route.params,' this.$route.params');
    
    // ClassTracking.checkUpload().then(() => {
    // this.pageInit()
    // })
    // if (!Vue.prototype.$isServer) {
    //   window.addEventListener('unload', this.unloadHandler, false)
    // }
  }

  beforeDestroy() {
    // this.classTrack()
  }

  beforeRouteUpdate(to: any, from: any, next: any) {
    // console.log(from, 'from');
    // console.log(to, 'to');
    
    
    // this.classTrack()
    // ClassTracking.checkUpload().then(() => {
    next()
    this.pageInit()
    // }, () => {
    //   next()
    //   this.pageInit()
    // })
  }

  getCharpterList() {
    return getChapterList({
      id: this.$route.query.contentId,
      count: 999
    }).then((e: any) => {
      console.log(e, '最外层');
      
      if (!e.error) {
        let charpterList: any = e.data
        charpterList.map((e: CharpterClass, i: number) => {
          e._index = i + 1
        })
        console.log(charpterList, 'charpterList');
        
        this.charpterList = charpterList
      } else {
        this.$message('目录:' + e.errorObj.title)
      }
    })
  }

  getCourse () {
    console.log(this.id, '有没有访问到');
    
    return getCourseDetail(this.courseId).then(res => {
      if (!res.error) {
        console.log(res, 'res');
        this.isLoading = false
        this.courseDetail = res
      } else {
        this.errorObj = {
          html: res.message || '课程详情服务繁忙'
        }
        this.isLoading = false
      }
    })
  }

  getClassData() {
    let { courseId, id } = this
    console.log(courseId, 'courseId');
    console.log(id, 'id');
    console.log(this.$route.query, 'this.$route.query');
    
    return getClassCourseDetail({
      course_id: courseId,
      class_id: this.$route.query.cId
    }).then(e => {
      console.log(e,'eee');
      
      if (!e.error) {
        this.afterData(e)
      } else if (e.error === 'no-pay') {
        this.afterData()
      } else {
        this.errorObj = {
          html: e.message || '课时详情服务繁忙'
        }
        this.isLoading = false
      }
    }, e => {
      this.errorObj = {
        html: e.message || '课时详情服务繁忙'
      }
      this.isLoading = false
    })
  }

  afterData(classDetail?: ClassInfo) {
    let courseDetail = this.courseDetail
    let isFree = classDetail ? classDetail.is_free : false
    let sub = courseDetail.is_subscribe
    let lockMode = this.courseDetail.study_mode === 'sequentially' && sub

    let p: any
    if (lockMode && sub) {
      p = this.getUnlockData()
    } else {
      p = Promise.resolve()
    }
    p.then(() => {
      this.$set(this.courseDetail, '_lockMode', lockMode)

      if (!classDetail || (!isFree && !sub)) {
        // 未订阅 又不是试看 去详情
        this.$router.replace({
          name: 'course',
          params: {
            id: this.courseId
          }
        })
        return
      } else if (classDetail.content_type === 'exam') {
        this.$showH5qrCode({
          name: 'CourseClass',
          params: {
            cId: this.cId,
            courseId: this.courseId
          }
        })
        return
      } else if (classDetail.content_type === 'live') {
        this.$showH5qrCode({
          name: 'CourseStudio',
          params: {
            id: this.cId,
            courseId: this.courseId
          },
          query: {
            classContentId: classDetail.content.content_id
          }
        })
        return
      } else if (lockMode && sub) {
        let targetIds: any = this.unlockData.target_ids || []
        targetIds = targetIds.map((e: any) => parseInt(e.split(':')[1]))

        let unlocked = targetIds.indexOf(classDetail.id) !== -1
        if (!unlocked) {
          this.$router.replace({
            name: 'course',
            params: {
              id: this.courseId
            }
          })
          this.$message('抱歉，该课时还未解锁，请先完成前置课时')
          return
          // classDetail._classLocked = true
        }
      }
      if(classDetail) {
        this.classDetail = classDetail
      }

      console.log(this.courseDetail, 'this.courseDetail');
      console.log(this.classDetail, 'this.courseDetail');
      console.log(this.unlockData, 'this.courseDetail');
      console.log(this.charpterList, 'this.courseDetail');
      

      getViewCount({id: this.classDetail.id})
      // 重置标题
      // utils.windowTitle(classDetail.title)

      this.isLoading = false
    })
  }

  getUnlockData() {
    let logined = checkLogin()
    if (!logined) return
    let userId = getSign().id
    let {content_id: contentId} = this.courseDetail
    return getUnlockList({userId, contentId}).then(e => {
      this.unlockData = e
    })
  }

  updateUnlockData(e: any) {
    console.log(e, 'kankanzheshi1');
    
    this.unlockData = e
  }

  pageInit() {
    this.courseDetail = {}
    this.classDetail = {}
    this.courseId = this.$route.query.contentId
    this.cId = this.$route.query.cId
    console.log(this.$route, 'this.courseId');
    
    // this.cId = this.$route.params.cId
    this.isLoading = true
    return Promise.all([this.getCharpterList(), this.getCourse()]).then(e => {
      return this.getClassData()
    })
  }
}
