export const counterTimeBySec = (sec: any) => {
  var range = sec;
  var counter: any = {};
  if (range < 0) {
    range = 0 - range;
    counter.valid = false;
  } else {
    counter.valid = true;
  }

  counter.day = operateTime(Math.floor(range / (24 * 60 * 60)));
  counter.hour = operateTime(Math.floor(range % (24 * 60 * 60) / (60 * 60)));
  counter.min = operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) / 60));
  counter.sec = operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) % 60));
  return counter;
};

export const operateTime = (time: any) => {
  return time < 10 ? '0' + time : time
}